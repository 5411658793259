$(function () {

    $('#burgerIcon').click(function() {
        $("#mainMenu").toggleClass("menuShowed");
    });

    $( ".nav__item" ).each(function(index) {
        $(this).on("click", function(){
            $(this).next().toggle();
            $(this).next().siblings('.nav__item-submenu').hide();
        });
    });

    $('.nav__item').each(function() {
        $(this).on( 'click', function() {
            $(this).toggleClass('activePage');
            $(this).siblings().removeClass('activePage');
        });
    });

    $(document).click(function(event) {
        if (!$(event.target).closest(".nav__item-submenu,.nav__item").length) {
          $("body").find(".nav__item-submenu").fadeOut();
          $('.nav__item').removeClass('activePage');
        }
        
    });

    $(window).scroll(function(){
        var sticky = $('#navigation'),
            scroll = $(window).scrollTop();
    
        if (scroll >= 1) {
            sticky.addClass('fixed');
        }
        else {
            sticky.removeClass('fixed');
        }
    });
});

